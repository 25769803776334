import React, { useState, useRef } from 'react';
import { createFileKey, UploadToCOS } from '@/utils/tcCOS';
import { getTcToken } from '@/utils/api';
import PropTypes from 'prop-types';
import { Spin, Icon, message } from 'antd';
import styles from './index.module.scss';
import VideoPlayer from '../VideoPlayer';
import Previews from '../Previews';

const defAccept = 'image/jpeg, image/jpg, image/png';
const defStyle = { width: '104px', height: '104px' };

function AliossUploadImage({ defaultValue, sty = {}, accept, tips, onChange }) {
  const upload = useRef(null);
  const [imgUrl, setImgUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState('');

  const handleGetUploadToken = async () => {
    const { uploadToken } = global || {};
    const { expiration } = uploadToken || {};
    const nowTime = Date.now() + 5 * 60 * 1000;
    const expira = new Date(expiration).getTime();
    if (expira <= nowTime || !uploadToken) {
      // 获取OSS鉴权
      const res = await getTcToken();
      global.uploadToken = res;
      return Promise.resolve(res);
    }
    return Promise.resolve(uploadToken);
  };

  const uploadImg = async (e) => {
    const file = e.target.files[0];
    const fileKey = createFileKey(file);
    const data = await handleGetUploadToken();
    if (!data || !file) return message.error('圖片選擇失敗');
    setLoading(true);
    UploadToCOS(data, file, fileKey)
      .then((fileUrl) => {
        onChange && onChange(fileUrl);
        setImgUrl(fileUrl);
        setLoading(false);
      })
      .catch(() => setLoading(false));
  };

  const imgPlaceholder = () => (
    <div className={styles.box} style={{ ...defStyle, ...sty }}>
      <Icon type='plus' className={styles.plus} />
      {tips && <div className={styles.text}>{tips}</div>}
    </div>
  );

  const imgContainer = (url) => {
    const resAccept = accept || defAccept;
    const prefix = resAccept.split(',')[0].split('/')[0];
    if (prefix === 'image') {
      return (
        <div className={styles.imgBox}>
          <div className={styles.options}>
            <Icon
              type='plus'
              className={styles.plus}
              onClick={() => {
                if (upload) {
                  upload.current.value = null;
                  upload.current.click();
                }
              }}
            />
            <Icon
              type='eye'
              className={styles.plus}
              onClick={() => setPreview(url)}
            />
          </div>
          <img src={url} alt='' />
        </div>
      );
    } else if (prefix === 'video') {
      return (
        <div className={styles.videoBox}>
          <div
            className={styles.uploads}
            onClick={() => {
              if (upload) {
                upload.current.value = null;
                upload.current.click();
              }
            }}
          >
            <Icon type='plus' className={styles.plus} />
          </div>
          <VideoPlayer videoUrl={url} />
        </div>
      );
    }
    return '';
  };

  function whichImg(imgUrl, defaultValue) {
    if (imgUrl) {
      return imgContainer(imgUrl);
    } else if (defaultValue) {
      return imgContainer(defaultValue);
    } else {
      return imgPlaceholder();
    }
  }

  return (
    <div style={{ ...defStyle, ...sty }} className={styles.uploadImgContainer}>
      <input
        type='file'
        ref={upload}
        accept={accept || defAccept}
        className={styles.uploadBtn}
        onChange={(e) => uploadImg(e)}
      />
      {loading ? (
        <div className={styles.loadingContainer}>
          <Spin />
        </div>
      ) : (
        whichImg(imgUrl, defaultValue)
      )}
      <Previews current={preview} onChange={() => setPreview('')} />
    </div>
  );
}

AliossUploadImage.propTypes = {
  defaultValue: PropTypes.string,
  onChange: PropTypes.func,
};

export default AliossUploadImage;
