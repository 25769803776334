import COS from 'cos-js-sdk-v5';
import { random } from 'lodash';
import { ALI_OSS_PREFIX } from './constants';

export const UploadToCOS = async (self, file, name, onProgress, options = {}) => {
  console.log("UploadToCOS:", self, file, name, onProgress, options)
  return new Promise((resolve, reject) => {
    const cos = new COS({
      SecretId: self.tmpSecretId, // sts服务下发的临时 secretId
      SecretKey: self.tmpSecretKey, // sts服务下发的临时 secretKey
      SecurityToken: self.sessionToken, // sts服务下发的临时 SessionToken
      // StartTime: self.StartTime, // 建议传入服务端时间，可避免客户端时间不准导致的签名错误
      ExpiredTime: self.expiredTime, // 临时密钥过期时间
    })
    cos.sliceUploadFile({
      Bucket: self.bucket, // 填入您自己的存储桶，必须字段
      Region: self.region,  // 存储桶所在地域，例如ap-beijing，必须字段
      Key: `${ALI_OSS_PREFIX}/${name}`,  // 存储在桶里的对象键（例如1.jpg，a/b/test.txt），必须字段
      Body: file, // 必须，上传文件对象，可以是input[type="file"]标签选择本地文件后得到的file对象
      onHashProgress: p => {
        const result = {
          key: name,
          percent: Math.round(p * 100).toFixed(2),
        };
        onProgress && onProgress(result);
      },
      ...options,
    })
      .then(data => {
        console.log("UploadToCOS then data:", data)
        const { statusCode, Key } = data || {};
        if (statusCode === 200) {
          resolve(`${self.cdnUrl}/${Key}`);
        } else {
          reject(data);
        }
      })
      .catch(error => {
        console.log("UploadToCOS error", error)
        reject(error);
      });
  });
};

export const createFileKey = file => {
  if (file) {
    const { type = '', name = '' } = file || {};
    const typeSuffix = type.toString().split('/')[1];
    const nameSuffix = name
      .toString()
      .split('.')
      .reverse()[0];
    const suffix = `.${typeSuffix || nameSuffix}`;
    const prefix = String(Date.now()) + random(1000000, 9999999);
    return `${prefix}${suffix}`;
  }
  return '';
};
